<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import { validationMixin } from '@/mixins/form/validation';
import { ErrorMessage, Field, Form } from 'vee-validate';
import Multiselect from '@vueform/multiselect';
import { mapGetters } from 'vuex';

export default {
  name: 'SwapPage',
  mixins: [validationMixin],
  page: {
    title: 'Swap',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  data() {
    return {
      title: this.$t('Swap.Title'),
      form: {
        transfer_from: null,
        transfer_to: null,
        amount_payment: null,
      },
      accounts: [],
      loading: {
        accounts: false,
        submitSwap: false,
      },
      defaultAmounts: [250, 500, 1000],
      toBeCredited: null,
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountsMap']),
    disabledSubmit() {
      return !this.form.transfer_from || !this.form.transfer_to || !this.form.amount_payment;
    },
    transferFromList() {
      return this.accounts
        .filter((acc) => {
          if (!this.form.transfer_to) return true;

          return acc.id !== this.form.transfer_to.id && this.form.transfer_to.is_demo === acc.is_demo;
        })
        .map((acc) => ({
          text: `${acc.name} | ${this.$t('Swap.Balance')} ${acc.amount} ${acc.currency.key}`,
          value: acc,
        }));
    },
    transferToList() {
      return this.accounts
        .filter((acc) => {
          if (!this.form.transfer_from) return true;

          return acc.id !== this.form.transfer_from.id && this.form.transfer_from.is_demo === acc.is_demo;
        })
        .map((acc) => ({
          text: `${acc.name} | ${this.$t('Swap.Balance')} ${acc.amount} ${acc.currency.key}`,
          value: acc,
        }));
    },
    currencyRatio() {
      const keyFrom = this.form.transfer_from?.currency?.key;
      const keyTo = this.form.transfer_to?.currency?.key;

      if (!keyFrom || !keyTo) {
        return 1;
      }

      if (this.$store.state.markets.markets[keyFrom + '/' + keyTo]) {
        return this.$store.state.markets.markets[keyFrom + '/' + keyTo].price;
      }

      if (this.$store.state.markets.markets[keyTo + '/' + keyFrom]) {
        return 1 / this.$store.state.markets.markets[keyTo + '/' + keyFrom].price;
      }

      return 1;
    },
  },
  watch: {
    'form.transfer_from.is_demo'(newValue) {
      if (this.form.transfer_to?.is_demo !== newValue) {
        this.form.transfer_to = null;
      }
    },
  },
  methods: {
    recalculatePayment() {
      const payment = (this.toBeCredited / this.currencyRatio).toFixed(2);
      this.form.amount_payment = payment;
      this.$refs.form.setFieldValue('transfer_amount', payment);
      this.$refs.form.validateField('transfer_amount');
    },
    recalculateCredited() {
      this.toBeCredited = (this.form.amount_payment * this.currencyRatio).toFixed(2);
    },
    fetchAccounts(values, actions) {
      this.loading.accounts = true;
      this.axios
        .get('/accounts')
        .then(({ data }) => {
          this.accounts = data;
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
        })
        .finally(() => {
          this.loading.accounts = false;
        });
    },
    submitSwap() {
      this.loading.submitSwap = true;

      this.axios
        .post(`/accounts/${this.form.transfer_from.id}/swap/${this.form.transfer_to.id}`, {
          amount: parseFloat(this.form.amount_payment),
        })
        .then(() => {
          this.$store.dispatch('accounts/getAccounts', true);
          this.$notify({
            type: 'success',
            text: this.$t('Swap.SuccessTransfer'),
          });
        })
        .finally(() => {
          this.loading.submitSwap = false;
        });
    },
  },
  mounted() {
    this.fetchAccounts();
  },
  metaInfo() {
    return {
      title: this.$t('Swap.Title'),
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <b-overlay :show="loading.accounts || loading.submitSwap" spinner-variant="primary" rounded="sm">
      <div class="card card-transparent mb-4">
        <div class="row">
          <div class="col-12">
            <div class="bg-white">
              <div class="form-title bordered">
                <div class="row justify-content-center">
                  <div class="col-xl-8 col-lg-10">
                    {{ $t('Swap.TranferingBetween') }}
                  </div>
                </div>
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-xl-8 col-lg-10">
                  <Form @submit="submitSwap" ref="form">
                    <Field
                      name="transfer_from"
                      rules="required"
                      :label="$t('Withdraw.CardForm.TransferFrom')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3" :label="$t('Swap.TransferFromAccount')">
                        <Multiselect
                          v-model="form.transfer_from"
                          :options="transferFromList"
                          :label="'text'"
                          :class="{ 'is-invalid': errors.length }"
                          searchable
                          :rtl="$i18n.locale === 'ar'"
                          :disabled="accounts.length < 2"
                          @update:modelValue="recalculateCredited"
                          v-bind="field"
                        />

                        <ErrorMessage name="transfer_from" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="transfer_to"
                      rules="required"
                      :label="$t('Swap.TransferTo')"
                      v-slot="{ errors, field }"
                    >
                      <b-form-group class="mb-3" :label="$t('Swap.TransferToAccount')">
                        <Multiselect
                          v-model="form.transfer_to"
                          :options="transferToList"
                          :label="'text'"
                          :rtl="$i18n.locale === 'ar'"
                          :class="{ 'is-invalid': errors.length }"
                          searchable
                          :disabled="accounts.length < 2"
                          @update:modelValue="recalculateCredited"
                          v-bind="field"
                        />
                        <ErrorMessage name="transfer_to" class="invalid-feedback" />
                      </b-form-group>
                    </Field>

                    <Field
                      name="transfer_amount"
                      :rules="`required|max_value:${
                        form.transfer_from === null ? Infinity : form.transfer_from.amount
                      }`"
                      :label="$t('Swap.AmountOfPayment')"
                      v-slot="{ errors, field }"
                    >
                      <div class="row align-items-baseline">
                        <div class="col-md-6">
                          <b-form-group class="mb-3 text-muted" :label="$t('Swap.AmountOfPayment')">
                            <b-form-input
                              v-model="form.amount_payment"
                              v-bind="field"
                              v-maska="{
                                mask: 'XZ*',
                                tokens: {
                                  X: { pattern: /[1-9]/ },
                                  Z: { pattern: /[0-9]/ },
                                },
                              }"
                              type="text"
                              :placeholder="$t('Swap.AmountOfPayment')"
                              :class="{ 'is-invalid': errors.length }"
                              @update:modelValue="recalculateCredited"
                            />
                            <ErrorMessage name="transfer_amount" class="invalid-feedback" />
                          </b-form-group>
                        </div>

                        <div v-if="form.transfer_from?.id" class="col-md-6">
                          <button
                            v-for="amount in defaultAmounts"
                            :key="amount"
                            type="button"
                            class="btn btn-soft-secondary btn-sm btn-rounded me-2 mt-1 mb-1"
                            @click="
                              form.amount_payment = amount;
                              $refs.form.setFieldValue('transfer_amount', amount);
                              $refs.form.validateField('transfer_amount');
                              this.recalculateCredited();
                            "
                          >
                            {{ amount }} {{ accountsMap[form.transfer_from.id]?.currency?.key }}
                          </button>
                        </div>
                      </div>
                    </Field>

                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group class="mb-3 text-muted" :label="$t('Swap.AmountToBeCredited')">
                          <b-form-input
                            v-model="toBeCredited"
                            type="text"
                            :placeholder="$t('Swap.AmountToBeCredited')"
                            @update:modelValue="recalculatePayment"
                          />
                        </b-form-group>
                      </div>
                    </div>

                    <div class="text-center mt-3">
                      <button type="submit" class="btn btn-dark btn-sm w-lg">{{ $t('Common.Continue') }}</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>

          <div class="d-none col-xl-3 col-12 description">
            <div class="form-title mb-3">Description</div>

            <p class="mb-5">Transfered funds will immediately be converted and credited to your trading account.</p>

            <div class="form-title mb-3">FAQ</div>

            <div class="accordion">
              <div v-for="index in 3" :key="index" class="faq-item">
                <button class="accordion-button fw-medium" type="button" v-b-toggle="`faq-${index}`">
                  {{ index }}. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </button>

                <b-collapse :id="`faq-${index}`" accordion="faq" role="tabpanel">
                  <b-card-body>
                    <b-card-text
                      >Depositing, withdrawal and transferring funds operations are made according to the company's
                      rates relevant as of the date of receipt of applications.</b-card-text
                    >
                  </b-card-body>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped lang="scss">
.accordion-button {
  color: #13172b;
  font-weight: 400;
}

.description {
  > * {
    @media (max-width: 1200px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
